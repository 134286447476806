import React, { useCallback, useMemo } from 'react'
import { Text } from 'components'
import { HelpDialog as RequestHelpDialog, HelpOption } from 'compositions/HelpDialog'
import { useCurrentVendorLocation, useSubmitVendorSupportRequest } from '@kitchen/hooks'
import type { HelpDialogActions, HelpDialogState } from 'hooks/useHelpDialog'

const helpDialogOptions: HelpOption[] = [
  { type: 'MENU_CHANGE', name: 'Report a menu change', notesRequired: true },
  { type: 'SERVICE_DISRUPTION', name: 'Report a disruption of service', notesRequired: true },
  { type: 'OTHER_ISSUE', name: 'I need something else', notesRequired: true },
]

const HelpDialogSuccess = () => <>
  <Text pb={2}>What to expect:</Text>
  <Text pb={2}>The Ordo Food Team will contact you as soon as possible with a resolution. In the case of an emergency, our lines are <a href="mailto:food@ordoschools.com">food@ordoschools.com</a> or <a href="tel:8557562909">855 756 2909</a>.</Text>
  <Text>If requesting a change to the menu, please wait 1-2 business days for a response before making a change to your menu.</Text>
</>

const HelpDialogError = () => <>
  <Text pb={2}>There was a problem submitting your request to the Ordo Food Team.</Text>
  <Text pb={2} fontWeight="bold">Please reload the page and try again.</Text>
  <Text>If you continue to experience problems submitting your request, you may contact us directly. Our lines are <a href="mailto:food@ordoschools.com">food@ordoschools.com</a> or <a href="tel:8557562909">855 756 2909</a>.</Text>
</>

export const HelpDialog: React.FC<HelpDialogState & HelpDialogActions> = ({
  open,
  selected,
  notes,
  errors,
  requestId,
  setHelpDialog,
  closeHelpDialog,
  clearHelpDialog,
}) => {

  const selectedOption = useMemo(() => helpDialogOptions.find((option) => option.type === selected), [ selected ])

  const { vendorLocation } = useCurrentVendorLocation()
  const [ submitVendorSupportRequest, { loading } ] = useSubmitVendorSupportRequest({ locationId: vendorLocation?.id, request: { type: selectedOption?.type, subject: selectedOption?.name, notes: notes } })

  const onChange = useCallback((state: Partial<HelpDialogState>) => setHelpDialog(state), [ setHelpDialog ])

  const submitHelpDialog = useCallback(() => {
    submitVendorSupportRequest().then(({ data }) => {
      setHelpDialog({
        errors: data.submitVendorSupportRequest.errors,
        requestId: data.submitVendorSupportRequest.requestId,
      })
    })
  }, [ submitVendorSupportRequest, setHelpDialog ])

  return <RequestHelpDialog
    open={open}
    notes={notes}
    requestId={requestId}
    errors={errors}
    selected={selectedOption}
    options={helpDialogOptions}
    loading={loading}
    SuccessMessage={HelpDialogSuccess}
    ErrorMessage={HelpDialogError}
    onClose={() => !!requestId ? clearHelpDialog() : closeHelpDialog()}
    onChange={onChange}
    onSubmit={submitHelpDialog}
  />
}

export default HelpDialog
